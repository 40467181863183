import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Header from './components/layout/Header';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import ServicesPage from './components/ServicesPage';
import AreasPage from './components/AreasPage';
import ContactPage from './components/ContactPage';

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <div className="min-h-screen bg-bg-primary">
          <Header />
          
          {/* Main Content */}
          <main className="py-8">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/services" element={<ServicesPage />} />
              <Route path="/areas" element={<AreasPage />} />
              <Route path="/contact" element={<ContactPage />} />
            </Routes>
          </main>

          {/* Footer */}
          <footer className="bg-bg-secondary shadow-md mt-auto">
            <div className="container mx-auto px-4 py-6">
              <div className="text-center text-text-secondary">
                <p>&copy; {new Date().getFullYear()} Tim's Tech. All rights reserved.</p>
              </div>
            </div>
          </footer>
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default App;