import React from 'react';
import { motion } from 'framer-motion';

const AreaCard = motion(React.memo(({ area, index }) => (
  <div className="areas-card" style={{ animationDelay: `${index * 50}ms` }}>
    <h3 className="text-lg text-text-primary">{area.name}</h3>
    <p className="text-sm text-text-secondary">{area.state}</p>
  </div>
)));

export default AreaCard;