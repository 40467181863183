import React from 'react';
import { Phone, Mail, MapPin, Clock } from 'lucide-react';
import { motion } from 'framer-motion';
import SEO from './shared/SEO';
import ContactForm from './forms/ContactForm';
import PageLayout from './layout/PageLayout';

const ContactPage = () => {
  const contactSchema = {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "Tim's Tech",
    "url": "http://www.tims-tech.com",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+1-781-366-8800",
      "contactType": "Customer Service"
    }
  };

  const handleSubmit = async (formData) => {
    const response = await fetch('/api/leads', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData)
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Failed to submit form');
    }
  };

  const contactInfo = [
    { icon: Phone, title: "Phone", content: "781-366-8800", gradient: "from-blue-600 to-cyan-500", link: "tel:781-366-8800" },
    { icon: Mail, title: "Email", content: "support@tims-tech.com", gradient: "from-purple-600 to-blue-600", link: "mailto:support@tims-tech.com" },
    { icon: MapPin, title: "Address", content: "130 Western Ave, Lowell, MA", gradient: "from-cyan-500 to-green-500" },
    { icon: Clock, title: "Hours", content: "24/7", gradient: "from-orange-500 to-amber-500" }
  ];

  return (
    <>
      <SEO
        title="Contact Tim's Tech | 24/7 IT Support Massachusetts"
        description="Need IT help? Contact Tim's Tech for 24/7 professional IT support in Massachusetts. Call (781) 366-8800 or visit our Lowell location."
        pathname="/contact"
      >
        <script type="application/ld+json">
          {JSON.stringify(contactSchema)}
        </script>
      </SEO>

      <PageLayout
        title="Contact Us"
        description="We're available 24/7 to help with all your technology needs"
      >
        {/* Contact Content */}
        <section className="contact-content-section">
          <div className="contact-content-container">
            <div className="contact-content-grid">
              {/* Contact Information */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                className="contact-info-card"
              >
                <h2 className="contact-info-title">Get In Touch</h2>
                
                <div className="space-y-6">
                  {contactInfo.map((info, index) => (
                    <motion.div 
                      key={index}
                      className="contact-info-item"
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.8, delay: 0.4 + (index * 0.1) }}
                    >
                      <a href={info.link} className={`contact-info-icon bg-gradient-to-r ${info.gradient}`}>
                        <info.icon className="w-full h-full text-white" />
                      </a>
                      <div>
                        <p className="contact-info-text">{info.title}</p>
                        <a href={info.link} className="contact-info-link">{info.content}</a>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </motion.div>

              {/* Contact Form */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
                className="contact-form-card"
              >
                <ContactForm onSubmit={handleSubmit} />
              </motion.div>
            </div>
          </div>
        </section>
      </PageLayout>
    </>
  );
};

export default ContactPage;