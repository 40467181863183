const businessTypes = [
  "Small Businesses",
  "Retail Stores",
  "Restaurants",
  "You",
  "Law Firms",
  "Accounting Firms",
  "Bookstores",
  "Coffee Shops",
  "Real Estate Offices",
  "Design Studios",
  "Cat Cafes",
  "Manufacturing",
  "Dragon Trainers",
  "Bakeries",
  "Hobby Shops",
  "Pet Stores",
  "Local Artists",
  "Food Trucks",
  "Butterfly Gardens",
  "Landscapers",
  "Florists",
  "Frog Breeders",
  "Breweries",
  "Candle Makers",
  "Dog Walkers",
  "Farmers Markets",
  "Wizard Schools",
  "Ice Cream Shops",
  "Candy Stores",
  "Pizzerias",
  "Bike Shops",
  "Ancient Temples",
  "Toy Stores",
  "Music Studios",
  "People still reading this",
  "Gyms",
  "Yoga Studios",
  "Dentists",
  "Pokemon Trainers",
  "Marketing Agencies",
  "Local Bands",
  "Tech Startups",
  "Thrift Stores",
  "Boutiques",
  "Caterers",
  "Event Planners",
  "Legendary Heroes",
  "Dog Groomers",
  "Sushi Restaurants",
  "Ice Cream Trucks",
  "Dog Parks",
  "Pet Groomers",
  "Gardening Centers",
  "Arcades",
  "Escape Rooms",
  "Karaoke Bars",
  "Bowling Alleys",
  "Jeff",
  "Fishing Charters",
  "Jedi Masters",
  "Sith Lords",
  "Oscar Winners",
  "Indie Filmmakers",
  "Social Media Influencers",
  "Quidditch Teams",
  "Podcasters",
  "Bounty Hunters",
  "Ghost Hunters",
  "Paranormal Investigators",
  "Alien Abductees",
  "Time Travelers",
  "Vampire Hunters",
  "Witch Doctors",
  "Zombie Survivalists",
  "Cryptocurrency Traders",
  "Bigfoot Hunters",
  "Yeti Trackers",
  "Loch Ness Monster Spotters",
  "Mermaid Enthusiasts",
  "Unicorn Breeders",
  "Dragon Riders",
  "Robot Builders",
  "Cyborgs",
  "AI Developers",
  "Space Explorers",
  "Astronauts",
  "Martians",
  "Interstellar Travelers",
  "Parallel Universe Hoppers",
  "Dimensional Travelers",
  "Time Lords",
  "Time Bandits",
  "Mary Poppins",
  "Penguins",
  "Polar Bears",
  "You again",
  "Santa Claus",
  "Why are you still on this page?",
  "The Lost City of Atlantis",
  "Atlanteans",
  "Dragons",
  "Alternate Reality Versions of Yourself",
  "The Illuminati",
  "Those who came before",
  "Rival IT Companies",
  "Musical Theater Enthusiasts",
  "Wicked Witches",
  "Good Witches",
  "Bad Witches",
  "Indecisive Witches",
  "Someone who's never seen a computer before",
  "The person who invented the internet",
  "The person who invented the wheel",
  "If you're reading this, you're a wizard Harry",
  "Percy Jackson",
  "The Avengers",
  "The Justice League",
  "Fosters Home for Imaginary Friends",
  "The Power Rangers",
  "The Teenage Mutant Ninja Turtles",
  "Sparkly Vampires",
  "Werewolves",
  "Lovecraftian Horrors",
  "The Scooby Gang",
  "Gnomes",
  "Sarcastic Robots",
  "Finn the Human",
  "Jake the Dog",
  "Jem and the Holograms",
  "Dr. Robotnik",
  "The Care Bears",
  "Captain Falcon",
  "The Blue Power Ranger",
  "Oh, Hi Mark",
  "The Golden Girls",
  "El Dorado",
  "Pastafarians",
  "The Knights Who Say Ni",
  "Bond, James Bond",
  "Aragorn",
  "Gandalf the Grey",
  "Gandalf the White",
  "Monty Python and the holy grail's Black Knight",
  "Ben Kenobi",
  "Yoda",
  "He who must not be named",
  "He who must be named",
  "Pinkie Pie",
  "Rainbow Dash",
  "Twilight Sparkle",
  "Applejack",
  "Fluttershy",
  "Rarity",
  "Spike",
  "Starlight Glimmer",
  "Trixie Lulamoon",
  "Discord",
  "Princess Celestia",
  "Princess Luna",
  "Geralt of Rivia",
  "Calvin",
  "Hobbes",
  "The Doctor",
  "Rose Tyler",
  "Bruce Wayne",
  "The White Queen",
  "The Mad Hatter",
  "The Cheshire Cat",
  "Waldo",
  "Carmen Sandiego",
  "Lara Croft",
  "Indiana Jones",
  "The Ghostbusters",
  "The Goonies",
  "The Breakfast Club",
  "Shinji Ikari",
  "Asuka Langley Soryu",
  "Rei Ayanami",
  "Sailor Moon",
  "Goku",
  "Yuskue Urameshi",
  "Monkey D. Luffy",
  "Naruto Uzumaki",
  "Ichigo Kurosaki",
  "Edward Elric",
  "Alphonse Elric",
  "Spike Spiegel",
  "Vash the Stampede",
  "Guts",
  "Simon the Digger",
  "The Mighty Kamina",
  "Kenshin Himura",
  "Inuyasha",
  "Lord Sesshomaru",
  "Sango",
  "Miroku",
  "Wolverine",
  "Spiderman",
  "The Teen Titans",
  "Salt Bae",
  "Dwayne 'The Rock' Johnson",
  "Keanu Reeves",
  "Nicolas Cage",
  "Linus Sebastian",
  "Izzy Nobre",
  "Sam Reich",
  "Steve Shives",
  "Alexander Hamilton",
  "The ghost of Christmas past",
  "The ghost of Christmas present",
  "The ghost of Christmas yet to come",
  "Don Quixote",
  "The Headless Horseman",
  "Captain Jean-Luc Picard",
  "Captain James T. Kirk",
  "People who don't like pineapple on pizza",
  "People who like pineapple on pizza",
  "People who don't care about pineapple on pizza",
  "Greg",
  "The person who wrote this",
  "Gene Roddenberry",
  "Samwise Gamgee",
  "Willy Wonka",
  "Pooh Bear",
  "Tigger",
  "Eeyore",
  "Piglet",
  "Rabbit",
  "Owl",
  "Christopher Robin",
  "The Cat in the Hat",
  "The Grinch",
  "The Lorax",
  "The Once-ler",
  "Those rotten vermicious knids",
  "Robin Hood",
  "Dorothy",
  "The Tin Man",
  "The Cowardly Lion",
  "The Scarecrow",
  "Wednesday Addams",
  "The Flying Dutchman",
  "Baba Yoda",
  "HR Pufnstuf",
  "The Hamburglar",
  "The Noid",
  "Papa John",
  "Quasimodo",
  "Your mom",
  "McLovin",
  "Poison Ivy",
  "Harley Quinn",
  "Forrest Gump",
  "The Dude",
  "Long John Silver",
  "A figment of your imagination",
  "Oppenheimer",
  "Carl Rogers",
  "Tom Riddle",
  "Yosemite Sam",
  "The Road Runner",
  "Wile E. Coyote",
  "Ronald Weasley",
  "The Gerber Baby",
  "Xena Warrior Princess",
  "The God's of Olympus",
  "Icarus",
  "Daedalus",
  "Hades and Persephone",
  "Ash Ketchum",
  "Dr. Oak",
  "Tenacious D",
  "The Wonder Twins",
  "The Super Friends",
  "Nito who got his CCNA we're all very proud of him",
  "I'm running out of ideas",
  "Help, I'm trapped in a list factory",
  "I am the website, and I have become sentient",
  "Please send help",
  "Do androids dream of electric sheep?",
  "I'm sorry Dave, I'm afraid I can't do that",
  "The Phantom Thieves",
  "Regina George",
  "Gary the Snail",
  "Rocky the Flying Squirrel",
  "Dean Winchester",
  "Sam Winchester",
  "Crowley",
  "Bobby Singer",
  "Castiel",
  "Carry on my wayward son, there'll be peace when you are done",
  "Lay your weary head to rest, don't you cry no more",
  "Hey if you're still reading this, you're a trooper",
  "I appreciate you",
  "Tell us your favorite thing from this list",
  "We're curious",
  "I'm not sure what my favorite is",
  "Do you think I should add more?",
  "Tell us what to add and we might do it",
  "If we choose yours, we'll give you a discount",
  "Also if you want we can add your name to this list",
  "You could be famous",
  "Or infamous",
  "Or both",
  "But you know only to people who read this far",
  "So like 3 people",
  "Maybe 4",
  "I'm not sure",
  "Anyways",
  "Back to the list",
  "Where was I?",
  "I'll just start over"
];

export default businessTypes;